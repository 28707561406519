import { Component, Vue, Prop } from "vue-property-decorator";
import { copyTextClipboard, getPaymentMethodLogo } from "@helpers";
import { PaymentMethod } from "@/types/billing";

@Component
export default class UsdtTransferHandle extends Vue {
  @Prop({ type: Object, required: true })
  private readonly data!: PaymentMethod;

  private get paymentMethodLogo() {
    return getPaymentMethodLogo(this.data.id);
  }

  private copyTextClipboard(val: string) {
    copyTextClipboard(val);
    this.$notify({
      type: "info",
      title: this.$vuetify.lang.t("$vuetify.info.copied"),
    });
  }
}
