var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('BaseImg',{staticClass:"mx-auto mb-2",attrs:{"src":_vm.paymentMethodLogo,"max-width":"160"}}),_c('p',{staticClass:"text-caption-2 text-center blue-grey--text text--darken-1 mb-5"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.billing.usdt_transfer.title"))+" ")]),_vm._l((_vm.data.externalFields),function(externalFieldValue,externalFieldName){return _c('div',{key:externalFieldName,staticClass:"d-flex align-center text-caption-1 mt-1"},[_c('b',{staticClass:"flex-shrink-0"},[_vm._v(" "+_vm._s(externalFieldName)+": ")]),_c('p',{staticClass:"text-truncate mb-0 flex-grow-1 ml-2 mr-3"},[_vm._v(" "+_vm._s(externalFieldValue)+" ")]),_c('v-btn',{staticClass:"flex-shrink-0",attrs:{"small":"","icon":"","color":"blue-grey lighten-3"},on:{"click":function($event){return _vm.copyTextClipboard(externalFieldValue)}}},[_c('v-icon',{attrs:{"size":"19"}},[_vm._v(" $copy ")])],1)],1)}),_c('p',{staticClass:"text-caption-1 text-left grey--text text--darken-1 mt-5"},[_c('span',{staticClass:"d-block",domProps:{"innerHTML":_vm._s(
        _vm.$vuetify.lang.t('$vuetify.dashboard.billing.usdt_transfer.text1')
      )}}),_c('span',{staticClass:"d-block mt-3",domProps:{"innerHTML":_vm._s(
        _vm.$vuetify.lang.t(
          '$vuetify.dashboard.billing.usdt_transfer.text2',
          _vm.data.minAmount
        )
      )}})])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }